import realisations from '../../data/data_rea';
import flickityImagesloaded from 'flickity-imagesloaded';
import Flickity from 'flickity';
import icoZoom from 'url:../img/icons/ico-zoom.svg';
//import FsLightbox from './fslightbox.js';

let flkty;

const sliderNav = document.querySelector('.slider_nav');

export default class Realisations {
  constructor() {
    window.addEventListener('load', this._buildReasDom());
  }

  _getData() {
    return realisations;
  }

  _buildReasDom() {
    const reasCtn = document.querySelector('#reas .slider_ctn');
    const realisations = this._getData();
    realisations.forEach(rea => {
      let reaImgsDom = '';
      rea.imgs.slides.forEach((slide, i) => {
        reaImgsDom += `<img src="img/static/rea_${slide}" alt="${
          rea.name
        } - image ${i + 1}">`;
      });
      const linkDOM = rea.url
        ? `<div class="rea_link"><a href="${rea.url}" class="btn btn--small btn--reverse" target="_blank">Voir le site web</a></div>`
        : '';
      const reasDom = `
        <div class="rea rea-${rea.slug}">
          <div class="rea_inner">
            <div class="rea_infos">
              <div class="text">
                <h3 class="rea_name">${rea.name}</h3>
                <div class="rea_desc">${rea.desc}</div>
              </div>
              ${linkDOM}
            </div>
            <div class="rea_images"><div class="img_ctn" data-gallery="${rea.imgs.slides.join(
              ','
            )}"><img class="rea_thumb" loading="lazy" src="img/static/rea_${
        rea.imgs.thumb
      }" alt="${
        rea.name
      }"><div class="rea_zoom"><img src="${icoZoom}" alt="Voir les images"></div></div></div>
          </div>
        </div>
      `;
      reasCtn.insertAdjacentHTML('beforeend', reasDom);
    });
    this._reaSlider();
    this._reaGallery();
    sliderNav.addEventListener('click', this._sliderNav);
  }

  _reaGallery() {
    document.querySelector('#reas').addEventListener('click', e => {
      if (!e.target.closest('.img_ctn')) return;
      const btnEl = e.target.closest('.img_ctn');
      const images = btnEl.dataset.gallery.split(',');
      const imagesPath = images.map(image => `img/static/rea_${image}`);

      const lightbox = new FsLightbox();
      lightbox.props.sources = imagesPath;
      lightbox.props.exitFullscreenOnClose = true;
      lightbox.props.loadOnlyCurrentSource = false;
      lightbox.open();
    });
  }

  _reaSlider() {
    flkty = new Flickity('.slider_ctn', {
      pageDots: false,
      prevNextButtons: false,
      draggable: '>1',
      cellAlign: 'center',
      contain: true,
      imagesLoaded: true,
      adaptiveHeight: true,
      cellSelector: '.rea',
    });
    return flkty;
  }

  _sliderNav(e) {
    e.preventDefault();
    const clickEl = e.target.closest('.slider_nav_item');
    const prevEl = document.querySelector('[data-direction="prev"]');
    const nextEl = document.querySelector('[data-direction="next"]');
    if (!clickEl) return;
    const direction = clickEl.dataset.direction;
    if (direction === 'next') {
      flkty.next();
    } else {
      flkty.previous();
    }
    flkty.selectedIndex === 0
      ? prevEl.classList.add('disabled')
      : prevEl.classList.remove('disabled');
    flkty.selectedIndex === flkty.cells.length - 1
      ? nextEl.classList.add('disabled')
      : nextEl.classList.remove('disabled');
  }
}
