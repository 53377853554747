const parcours = [
  {
    current: false,
    slug: 'yoma',
    name: 'Front-End Developer',
    type: 'work', // work / diploma
    date: 'juillet 2019 - avril 2021',
    dateLength: '1 an et 8 mois',
    location: 'Liverpool, UK',
    place: {
      name: 'Yoma',
      link: 'https://www.yoma.co.uk',
      logo: 'yoma-logo.png',
    },
    desc: 'Mon dernier emploi fût d’être développeur front-end pour <em>Yoma</em>, une agence web et marketing spécialisé en e-commerce et Magento. Je fus responsable de la création et modification de thèmes afin de réaliser le design approuvé par les clients. J’avais également la responsabilité de faire les ajustements nécessaires à l’intégration lors de l’installation de module externe(third party) et de m’assurer que les modules fonctionnaient correctement. J’ai également eu l’opportunité d’être impliqué dans l’intégration de fonctionnalité d’un ERP(Epicor) avec des thèmes.',
    listItems: [
      'Installation de Magento 2',
      'Légé développement PHP & JS',
      'Création de module Magento',
      'Participation à la création de devis.',
    ],
  },
  {
    current: false,
    slug: 'quatre-cinq',
    name: 'Intégrateur web',
    type: 'work',
    date: 'novembre 2014 - mars 2019',
    dateLength: '4 ans',
    location: '',
    place: {
      name: 'Quatre & Cinq',
      link: 'https://quatreetcinq.com',
      logo: 'logo45.svg',
    },
    desc: 'J’ai travaillé quatre ans pour <em>Quatre & Cinq</em>. Mon rôle principal était intégrateur web, mais le fait que nous étions une petite équipe m’a permis de mettre en pratique une multitude de compétences. J’ai été impliqué dans l’ensemble des étapes d’un projet : définition des besoins du client, choix des technologies employés, création du devis, intégration du site, tests et mise en ligne, formation client et la maintenance des sites web. Ce fut un environnement de travail dynamique qui m’a demandé d’être constamment à l’affût des dernières technologies.',
    listItems: [
      'Intégration HTML & CSS avec Drupal, Prestashop et Wordpress',
      'Légé développement PHP & JS',
      'Recherche de solutions techniques',
      'Gestion de projet, Formation client',
      'Participation à la création de devis.',
    ],
  },
  {
    current: false,
    slug: 'mayflower',
    name: 'Intégrateur web (stage)',
    type: 'work',
    date: 'mai 2014 - septembre 2014',
    dateLength: '4 mois',
    location: 'Lyon, France',
    place: {
      name: 'Agence Mayflower',
      link: 'https://www.agencemayflower.com/',
      logo: 'mayflower.png',
    },
    desc: 'J’ai travaillé 4 mois pour l’Agence Mayflower dans le cadre d’un stage. Ce stage m’a non seulement permis de mettre en pratique plusieurs connaissances acquises à l’université ou de façon autodidacte, mais également d’apprendre l’usage de plusieurs outils de travail collaboratif tel que GIT. Cette expérience m’a aussi d’observer concrètement le fonctionnement d’une agence et les différentes étapes de réalisation d’un projet.',
    listItems: [
      'Intégration HTML & CSS avec Drupal, Prestashop et Wordpress',
      'Légé développement PHP & JS',
      'Création et optimisation d’images à des fins d’affichage web',
      'Recettage',
    ],
  },
  {
    current: false,
    slug: 'bayonne',
    name: 'Communication multimédia',
    type: 'diploma',
    date: 'octobre 2013 - septembre 2014',
    dateLength: '1 an',
    location: 'Licence Professionelle (Diplôme universitaire - 1 an)',
    place: {
      name: 'IUT de Bayonne et du Pays Basque',
      link: 'https://www.iutbayonne.univ-pau.fr/licences-pro/metiers-numerique-conception-web',
      logo: 'iut.png',
    },
    desc: 'J’ai eu la chance d’étudier un an en France. La licence professionnelle est un diplôme d’études universitaire d’une durée d’un&nbsp;an, basé sur 8 mois de cours, puis d’un stage de 4 mois. La formation se rapproche beaucoup de la réalité du marché du travail de par ses nombreux cours pratiques et ses projets très près de ce que l’on peut trouver en entreprise. De plus, la longueur du stage nous permet de mettre en pratique la majorité des connaissances acquises à l’université.',
    listItems: [
      'Programmation en PHP et JavaScript',
      'Analyse, rédaction et conception liée à la réalisation d’un site web',
      'Gestion de projet web',
      'Capture, traitement et conversion de fichiers audio et vidéo',
    ],
  },
  {
    current: false,
    slug: 'quebec-amerique',
    name: 'Infographiste',
    type: 'work',
    date: 'octobre 2010 - août 2013',
    dateLength: '3 ans',
    location: '',
    place: {
      name: 'Les éditions Québec Amérique',
      link: 'https://www.quebec-amerique.com/',
      logo: 'qa.jpg',
    },
    desc: 'J’ai eu la chance de travailler trois ans pour <em>Québec Amérique</em>. J’ai adoré ce travail qui m’a permis d’exercer mes connaissances en imprimé, et m’a ouvert sur les possibilités du numérique et plus particulièrement le web.',
    listItems: [
      'Production de dictionnaires illustrés multilingues et de PDF destinés aux imprimeurs',
      'Production de livres numériques&thinsp;(PDF et EPUB)',
      'Optimisation d’images pour une utilisation sur le web',
      'Intégration pour le développement d’applications numériques',
      'Validation des épreuves papier et des applications numériques',
    ],
  },
  {
    current: false,
    slug: 'ahuntsic',
    name: 'Infographie en prémédia',
    type: 'diploma',
    date: 'mai 2007 - septembre 2010',
    dateLength: '3 ans',
    location: 'Diplôme d’études collégiales',
    place: {
      name: 'Collège Ahuntsic',
      link: 'https://www.collegeahuntsic.qc.ca/programmes-dec/techniques/infographie-en-premedia',
      logo: 'ahuntsic.png',
    },
    desc: 'En trois ans d’études, j’ai eu l’occasion de maîtriser les logiciels phares d’Adobe et d’acquérir plusieurs bases en conception graphique. C’est un atout très important qui me permet d’avoir un esprit critique sur les maquettes que j’intègre et d’avoir un impact sur le résultat de mon travail. C’est également lors de la complétion de ce diplôme que j’ai appris les bases du HTML et du CSS et qui a fait que je suis maintenant intégrateur web&thinsp;!',
    listItems: [
      'Maîtrise de Photoshop, Illustrator et Indesign',
      'Traitement des images pour un usage numérique',
    ],
  },
];

export default parcours;
