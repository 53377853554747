const skills = [
  {
    slug: 'html',
    name: 'Html5',
    type: 'Langage',
    power: 3,
  },
  {
    slug: 'css',
    name: 'CSS(SCSS, LESS)',
    type: 'Langage',
    power: 3,
  },
  {
    slug: 'js',
    name: 'JavaScript',
    type: 'Langage',
    power: 3,
  },
  {
    slug: 'php',
    name: 'PHP',
    type: 'Langage',
    power: 2,
  },
  {
    slug: 'gulp',
    name: 'Gulp',
    type: 'Outil',
    power: 2,
  },
  {
    slug: 'wp',
    name: 'Wordpress',
    type: 'CMS',
    power: 2,
  },
  {
    slug: 'magento',
    name: 'Magento',
    type: 'CMS',
    power: 2,
  },
  {
    slug: 'gsap',
    name: 'GSAP',
    type: 'Outil',
    power: 1,
  },
  {
    slug: 'figma',
    name: 'Figma',
    type: 'Design',
    power: 1,
  },
  {
    slug: 'bootstrap',
    name: 'Bootstrap',
    type: 'framework',
    power: 2,
  },
  {
    slug: 'foundation',
    name: 'Foundation',
    type: 'framework',
    power: 2,
  },
  {
    slug: 'webpack',
    name: 'WebPack',
    type: 'Outil',
    power: 1,
  },
  {
    slug: 'photoshop',
    name: 'Photoshop',
    type: 'Design',
    power: 2,
  },
  {
    slug: 'drupal',
    name: 'Drupal',
    type: 'CMS',
    power: 1,
  },
  {
    slug: 'git',
    name: 'GIT',
    type: 'Outil',
    power: 2,
  },
  {
    slug: 'seo',
    name: 'SEO',
    type: 'Outil',
    power: 2,
  },
  {
    slug: 'jira',
    name: 'Jira',
    type: 'Gestion',
    power: 2,
  },
  {
    slug: 'prestashop',
    name: 'Prestashop',
    type: 'CMS',
    power: 2,
  },
  {
    slug: 'sketch',
    name: 'Sketch',
    type: 'Design',
    power: 1,
  },
];

export default skills;
