import parcours from '../../data/data_parcours';
import icoWork from 'url:../img/icons/ico-work.svg';
import icoDiploma from 'url:../img/icons/ico-diploma.svg';

export default class Skills {
  constructor() {
    window.addEventListener('load', this._buildSkillsDom());
    document
      .querySelector('#popup_close')
      .addEventListener('click', this._clickClose.bind(this));
    document.addEventListener('keyup', this._escapeClose.bind(this));
    document
      .querySelector('#cv')
      .addEventListener('click', this._showModal.bind(this));
  }

  _getData() {
    return parcours;
  }

  _buildSkillsDom() {
    const parcoursCtn = document.querySelector('#cv');
    const parcours = this._getData();
    parcours.forEach(item => {
      let tacheDom = '';
      item.listItems.forEach(tache => {
        tacheDom += `<li>${tache}</li>`;
      });
      const listTitle =
        item.type === 'work' ? 'Description de tâche' : 'Principaux acquis';
      const diplomeDom =
        item.location !== '' ? `<p class="diplome">${item.location}</p>` : ``;

      const parcourDom = `
        <div class="item parcour_${item.slug}${
        item.current ? ' current' : ''
      }" data-parcours-type="${item.type}" data-target="${item.slug}">
          <div class="date"><time>${item.date}</time><div class="duree">(${
        item.dateLength
      })</div></div>
          <a href="#" class="infos">
            <div class="icon"><img src="${
              item.type === 'work' ? icoWork : icoDiploma
            }" alt="${item.type === 'work' ? 'Emploi' : 'Diplôme'}"></div>
            <div class="texte">
              <h3 class="titre">${item.name}</h3>
              <p class="lieu">${item.place.name}</p>
              ${diplomeDom}
              <div class="details"><span class="sr-text">Détails</span></div>
            </div>
          </a>
        </div>
      `;
      const popupsDom = `
        <div class="popup_item" id="${item.slug}">
          <div class="popup_inner">
            <div class="top">
              <div class="intro">
                <div class="icon"><img src="${
                  item.type === 'work' ? icoWork : icoDiploma
                }" alt="${item.type === 'work' ? 'Emploi' : 'Diplôme'}"></div>
                <div class="texte">
                  <h3 class="titre">${item.name}</h3>
                  <p class="lieu"><a href="${
                    item.place.link
                  }" target="_blank">${item.place.name}</a></p>
                </div>
              </div>
              <div class="popup_logo"><div class="popup_logo_inner"><img src="img/static/${
                item.place.logo
              }" alt="${item.place.name}"></div></div>
            </div>
            <div class="contenu">
              <div class="description">${item.desc}</div>
              <div class="taches">
                <h3>${listTitle}</h3>
                <ul>${tacheDom}</ul>
              </div>
            </div>
          </div>
        </div>
      `;
      parcoursCtn.insertAdjacentHTML('beforeend', parcourDom);
      document
        .querySelector('#site')
        .insertAdjacentHTML('beforeend', popupsDom);
    });
  }

  _showModal(e) {
    e.preventDefault();
    const itemClicked = e.target.closest('.item');
    if (!itemClicked || document.body.classList.contains('--with-overlay'))
      return;
    const target = itemClicked.dataset.target;
    document
      .querySelectorAll('.popup_item')
      .forEach(el => (el.style.display = 'none'));

    document.body.classList.add('--with-overlay');
    document.querySelector(`#${target}`).style.display = 'flex';
  }

  _clickClose(e) {
    e.preventDefault();
    this._closeModal();
  }

  _escapeClose(e) {
    if (e.key !== 'Escape') return;
    this._closeModal();
  }

  _closeModal() {
    if (!document.body.classList.contains('--with-overlay')) return;

    document
      .querySelectorAll('.popup_item')
      .forEach(el => (el.style.display = 'none'));
    document.body.classList.remove('--with-overlay');
  }
}
