import scrolls from 'scrolls.js';

export default class Scroll {
  constructor() {
    this._homeScroll();
    this._menuScroll();
    this._sectionScroll();
  }

  onMenuScrollEvent() {
    window.dispatchEvent(new CustomEvent('menuscroll'));
  }

  _homeScroll() {
    document.querySelector('#float_logo').addEventListener('click', e => {
      scrolls('#header');
    });
  }

  _menuScroll() {
    const links = document.querySelectorAll('#menu a');
    if (links) {
      links.forEach(link => {
        const section = link.getAttribute('href').replace('#', '');

        link.addEventListener('click', e => {
          e.preventDefault();

          scrolls('#' + section);
          this.onMenuScrollEvent();
        });
      });
    }
  }

  _sectionScroll() {
    const sectionNavBtns = document.querySelectorAll('.section_next .btn');
    if (sectionNavBtns) {
      sectionNavBtns.forEach(nextBtn => {
        const section = nextBtn.getAttribute('href').replace('#', '');

        nextBtn.addEventListener('click', e => {
          e.preventDefault();

          scrolls('#' + section, {
            callback: () => {
              console.log('Scrolling has finished.');
            },
          });
        });
      });
    }
  }
}
