const rea = [
  {
    slug: 'auditerra',
    name: 'Auditerra',
    url: 'https://auditerra.ca/',
    desc: 'C’est un site web créé afin d’établir une présence web pour une firme de consultant en développement durable. C’est un site fonctionnant sous Wordpress avec un thème que j’ai créé à partir de zéro. Le thème est créé de manière à ce les administrateurs du site puissent avoir l’entièreté du contrôle sur le contenu, sans compromettre l’intégrité du design. Le framework CSS utilisé à la base de ce thème fût Foundation afin de gérer le responsive et facilité l’usage de Flexbox CSS.',
    imgs: {
      thumb: 'auditerra_thumb.jpg',
      slides: [
        'auditerra.jpg',
        'auditerra_force.jpg',
        'auditerra_raisons.jpg',
        'auditerra_equipe.jpg',
      ],
    },
  },
  {
    slug: 'coop',
    name: 'Coop Le Coteau Vert',
    url: 'http://coteauvert.com/',
    desc: 'C’est un site web créé pour une coopérative d’habitation. Le but du site est d’offrir une plateforme d’information sur la coopérative pour les non-membres. <em>Wordpress</em> a été utilisé pour sa facilité d’utilisation et sa gestion d’usager afin de rendre la gestion du site web aisé pour les membres du comité des communications de la coopérative et de leur donner le contrôle sur l’ensemble du contenu.',
    imgs: {
      thumb: 'coopCoteauVert_thumb.jpg',
      slides: [
        'coopCoteauVert.jpg',
        'coopCoteauVert_accueil.jpg',
        'coopCoteauVert_coop.jpg',
        'coopCoteauVert_apropos.jpg',
        'coopCoteauVert_faq.jpg',
      ],
    },
  },
  {
    slug: 'firstchoice',
    name: 'Firstchoice',
    url: 'https://www.firstchoice-cs.co.uk/',
    desc: 'Firstchoice est un site de e-commerce B2B et B2C réalisé avec <em>Magento 2</em>. Le site de <em>Firstchoice</em> a requis beaucoup de développement personnalisé, autant dans la gestion des produits que dans l’interface. Cette installation de <em>Magento</em> a la particularité d’être lié à <em>Epicor</em>, un ERP, ce qui a nécessité des modifications importantes au fonctionnement de base de Magento. Un thème à été créé spécialement pour ce site.',
    imgs: {
      thumb: 'firstchoice_thumb.jpg',
      slides: [
        'firstchoice.jpg',
        'firstchoice_categorie.jpg',
        'firstchoice_product.jpg',
        'firstchoice_quickorder.jpg',
        'firstchoice_manufacturers.jpg',
      ],
    },
  },
  {
    slug: 'ouiiohe',
    name: 'OUI-IOHE',
    url: 'https://www.firstchoice-cs.co.uk/',
    desc: '<em>OUI-IOHE</em> est un site web d’une organisation facilitant la relation entre les universités au sein des continents américains. C’est un site créé à partir de <em>Wordpress</em> avec un thème créé à partir de zéro. Le thème est créé de manière à ce les administrateurs du site puissent avoir l’entièreté du contrôle sur le contenu, sans compromettre l’intégrité du design. Le framework CSS utilisé à la base de ce thème fut <em>Foundation</em> afin de gérer le responsive et faciliter l’usage  de Flexbox CSS.',
    imgs: {
      thumb: 'ouiiohe_thumb.jpg',
      slides: [
        'ouiiohe.jpg',
        'ouiiohe_accueil.jpg',
        'ouiiohe_membres.jpg',
        'ouiiohe_nouvelles.jpg',
        'ouiiohe_contact.jpg',
      ],
    },
  },
  {
    slug: 'partageWeb',
    name: 'Partage Web',
    url: false,
    desc: '<em>Partage Web</em> fût un projet personnel il y a plusieurs années. C’est un annuaire permettant de trouver des outils et librairies reliés à la création de sites internet. Ce site fonctionnait sous <em>Wordpress</em>, avec un thème créé avec <em>Bootstrap</em>. Pour ce projet, j’ai développé un plugin permettant aux visiteurs de proposer leurs propres outils avec un formulaire. La soumission du formulaire, après une validation, créait automatiquement un post Wordpress avec un statut personnalisé. J’avais également développé une fonctionnalité me permettant d’importer des outils en masse en important un fichier CSV.',
    imgs: {
      thumb: 'partageWeb_thumb.jpg',
      slides: [
        'partageWeb.jpg',
        'partageWeb_fiche.jpg',
        'partageWeb_recherche.jpg',
        'partageWeb_proposition.jpg',
        'partageWeb_contact.jpg',
      ],
    },
  },
  {
    slug: 'sotfr',
    name: 'Sotfr.com (Forum)',
    url: false,
    desc: 'Sotfr fut un forum que j’ai créé à l’intention de la communauté francophone du jeu <em>Sea Of Thieves</em>. Le forum a été conçu à l’aide de <em>PhpBB</em>, un CMS spécialisé pour la création de forums. J’ai utilisé un thème de base que j’ai modifié afin d’ajuster le design dans le genre du jeu (piraterie) tout en restant relativement sobre.',
    imgs: {
      thumb: 'sotfr_thumb.jpg',
      slides: [
        'sotfrForum.jpg',
        'sotfr_sujet.jpg',
        'sotfr_recherche.jpg',
        'sotfr_membres.jpg',
        'sotfr_profil.jpg',
      ],
    },
  },
  {
    slug: 'tangente',
    name: 'Tangente',
    url: 'https://tangentedanse.ca/',
    desc: 'Tangente est un site pour une organisation qui produit et promeut la danse contemporaine à Montréal. L’objectif du site est d’informer le public de leur mission et des spectacles produits. <em>Wordpress</em> est le CMS utilisé pour la création de ce site web, afin de donner 100% du contrôle du contenu aux administrateurs.',
    imgs: {
      thumb: 'tangente_thumb.jpg',
      slides: [
        'tangente.jpg',
        'tangente_evenements.jpg',
        'tangente_evenement.jpg',
        'tangente_recherche.jpg',
        'tangente_billet.jpg',
        'tangente_contact.jpg',
      ],
    },
  },
  {
    slug: 'xyz',
    name: 'Éditions XYZ',
    url: 'http://editionsxyz.com/',
    desc: 'Éditions XYZ est l’un des sept sites web créer pour un groupe composé de plusieurs maisons d’édition. Un de ces sites est une plateforme de gestion permettant au groupe de gérer l’entièreté des livres publiés au sein de leurs différentes maisons d’édition. La plateforme de gestion a été créée avec <em>Laravel</em>, puis les six sites des maisons d’édition ont été créés avec <em>Wordpress</em>. Pour les sites sous <em>Wordpress</em>, un thème parent a été créé à partir de zéro, puis des thèmes enfant ont été mis en place pour personnaliser leur apparence en fonction des différentes maisons d’édition. <em>Foundation</em> is used as the CSS framework on these projects.',
    imgs: {
      thumb: 'xyz_thumb.jpg',
      slides: [
        'xyz.jpg',
        'xyz_catalogue.jpg',
        'xyz_livre.jpg',
        'xyz_auteurs.jpg',
        'xyz_auteur.jpg',
        'xyz_prix.jpg',
        'xyz_contact.jpg',
      ],
    },
  },
];

export default rea;
