const htmlEl = document.querySelector('html');
const btnToggleTheme = document.querySelector('[data-toggle-color-mode]');

export default class ColorScheme {
  constructor() {
    // color scheme management
    this._setColorMode();
    btnToggleTheme.addEventListener('click', this._toggleColorMode.bind(this));
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener(
      'change',
      e => e.matches && this._setColorMode('dark') // listener
    );
    window.matchMedia('(prefers-color-scheme: light)').addEventListener(
      'change',
      e => e.matches && this._setColorMode('light') // listener
    );
  }

  // color scheme management
  _setColorMode(mode = this._getColorMode()) {
    htmlEl.dataset.colorMode = mode;
  }

  _getColorMode() {
    const browserMode = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'dark'
      : 'light';

    return htmlEl.dataset.colorMode ? htmlEl.dataset.colorMode : browserMode;
  }

  _toggleColorMode() {
    this._getColorMode() === 'dark'
      ? (htmlEl.dataset.colorMode = 'light')
      : (htmlEl.dataset.colorMode = 'dark');
  }
}
