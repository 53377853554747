import skills from '../../data/data_skills';
import Masonry from 'masonry-layout';
import textfit from 'textfit/textFit';

export default class Skills {
  constructor() {
    window.addEventListener('load', this._buildSkillsDom());
  }

  _getData() {
    return skills;
  }

  _buildSkillsDom() {
    const skillsCtn = document.querySelector('#skills');
    const skills = this._getData();
    skills.forEach(skill => {
      const skillDom = `
        <div class="skill skill-${skill.slug}" data-skill-type="${skill.type}" data-skill-power="${skill.power}">
          <div class="skill_inner">
            <span class="skill_icon"></span>
            <span class="skill_name">${skill.name}</span>
          </div>
        </div>
      `;
      skillsCtn.insertAdjacentHTML('beforeend', skillDom);
    });
    this._masonryInit();
    this._textFit();
  }

  _masonryInit() {
    const skillsEl = document.querySelector('#skills');
    const skillGrid = new Masonry(skillsEl, {
      itemSelector: '.skill',
      columnWidth: '.grid_sizer',
      gutter: 0,
      percentPosition: true,
      horizontalOrder: false,
    });
  }

  _textFit() {
    const skillNames = document.querySelectorAll('.skill_name');
    textfit(skillNames);
    skillNames.forEach(el => {
      el.style.height = 'auto';
    });
  }
}
