export default class SlideMenu {
  #btnsToggleMenu = document.querySelector('[data-menu-toggle]');
  #menu = document.querySelector(this.#btnsToggleMenu.dataset.menuToggle);
  #siteEl = document.querySelector('body');

  constructor() {
    this.#btnsToggleMenu.addEventListener('click', this._toggleMenu.bind(this));
  }

  _toggleMenu() {
    this.#btnsToggleMenu.classList.toggle('--open');
    this.#menu.classList.toggle('--open');
    this.#siteEl.classList.toggle('menu--open');
    if (this.#menu.classList.contains('--open')) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  }

  closeMenu() {
    this.#btnsToggleMenu.classList.remove('--open');
    this.#menu.classList.remove('--open');
    this.#siteEl.classList.remove('menu--open');
    document.body.style.overflowY = 'visible';
  }
}
