import ColorScheme from './colorScheme';
import Scroll from './scroll';
import SlideMenu from './slideMenu';
import TypeIt from 'typeit';
import Skills from './skills';
import Parcours from './parcours';
import Realisations from './realisations';

const menu = new SlideMenu();
new ColorScheme();
new Skills();
new Parcours();
new Realisations();

// Header Section Typing
const presentationHolder = document.querySelector('.site__presentation');

const presentationTyping = new TypeIt(presentationHolder, {
  strings: presentationHolder.dataset.typeit.split('<br/>'),
  speed: 45,
  nextStringDelay: 1200,
  breakLines: true,
  waitUntilVisible: true,
  afterComplete: () => {
    setTimeout(() => {
      document.querySelector('.ti-cursor').remove();
    }, 2900);
  },
}).go();

// Scroll Mechanics
new Scroll();
window.addEventListener('menuscroll', () => menu.closeMenu());

// Sticky Logo
const header = document.querySelector('#header');
const logo = document.querySelector('#float_logo');

const logoToggle = entries => {
  const [entry] = entries;
  if (!entry.isIntersecting) {
    document.body.classList.add('menu_sticky');
    logo.classList.remove('hide');
  } else {
    document.body.classList.remove('menu_sticky');
    logo.classList.add('hide');
  }
};

const headerObserver = new IntersectionObserver(logoToggle, {
  root: null,
  threshold: 0.5,
});
headerObserver.observe(header);
